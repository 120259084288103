<template>
  <section>
    <kn-table
      v-if="!showInactive"
      :headers="headers"
      :items="items"
      :loading="loading"
      tableTitle="Colaboradores"
      newButtonLabel="Agregar empleado"
      :showBottomAction="true"
      @action2="disableEmployee"
      @showAction="showInactive = true"
    />
    <kn-inactive-items-table
      v-if="showInactive"
      :headers="headers"
      :items="inactiveItems"
      tableTitle="Colaboradores"
      newButtonLabel="Agregar empleado"
      :showBottomAction="true"
      @action2="enableEmployee"
      @showAction="showInactive = false"
    />
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import KnTable from '@/components/tables/KnTable.vue'
import { apiMixin } from '@/mixins/apiMixin.js'
import KnInactiveItemsTable from '@/components/tables/KnInactiveItemsTable.vue'
export default {
  components: { KnTable, KnInactiveItemsTable },
  mixins: [apiMixin],
  data() {
    return {
      groups: [],
      employees: [],
      headers: [
        { text: '', value: 'foto' },
        { text: 'Nombre', value: 'nombre', class: 'purple--text' },
        { text: 'Grupo', value: 'grupo', class: 'purple--text' },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones', class: 'purple--text' },
      ],
      items: [],
      inactiveItems: [],
      search: null,
      showInactive: false,
      loading: true
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData'])
  },
  async created() {
    this.setIsLogin(false)
    this.loading = true
    this.groups = await this.fetchResultsByEI('personas', 'grupo', this.institutionId)
    this.employees = await this.fetchResultsByEI('personas', 'empleado', this.institutionId)
    await this.getEmployees()
    this.loading = false
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async getEmployees() {
      try {
        this.employees.forEach(employee => {
          const {
            primer_nombre: pn,
            segundo_nombre: sn,
            apellido_paterno: ap,
            apellido_materno: am
          } = employee.datos_personales
          const fullName = `${pn} ${sn} ${ap} ${am}`
          const filterGroup = this.groups.filter(grp => {
            return grp.profesores.find(prof => prof.id === employee.id)
          })
          let group = 'Sin asignar'
          let groupId = null
          if (filterGroup && filterGroup.length > 0) {
            group = filterGroup[0].nombre_grupo
            groupId = filterGroup[0].id
          }
          // console.log('Se encontró el grupo? ', group);
          const item = {
            foto: employee.foto_perfil,
            nombre: fullName,
            grupo: group,
            id: employee.id,
            groupId: groupId,
            ...employee
          }

          if (item.estatus_sistema) {
            this.items.push(item)
          } else {
            this.inactiveItems.push(item)
          }
        });
      } catch (error) {
        console.error('Error al obtener empleados. ', error);
      }
    },
    /**
     * Desactiva un empleado. En caso de que tenga
     * asignado un grupo entonces lo desasigna
     * @param {number} employeeId Id del empleado a desactivar
     */
    async disableEmployee(employeeId) {
      try {
        // console.log('value', employeeId);
        const employee = this.items.find(e => e.id === employeeId)
        // console.log('Empleado seleccionado', employee);
        const groupId = employee.groupId
        /**
         * Si el empleado tiene asignado un grupo
         * se debe mandar a desasignar
         */
        if (groupId !== null) {
          const responseGroup = await this.postObj('/personas/remove-profesores-grupo', {
            id_profesor: employeeId,
            id_grupo: groupId
          },
          'remover')
          if (responseGroup.mensaje.includes('Empleado removido con éxito')) {
            // this.alertText = 'Alumno desasignado de grupo exitosamente'
          }
        }
        /**
         * Independiente de si tiene grupo o no
         * se manda a eliminar empleado. Eliminar
         * no elimina solo cambia el estatus_sistema
         * a false
         */
        const responseEmployee = await this.postObj('/personas/delete-empleado', {
          id_empleado: employeeId
        },
        'eliminar')
        if (responseEmployee.mensaje) {
          this.items = this.items.filter(e => e.id !== employeeId)
          this.inactiveItems.push(employee)
        }

      } catch (error) {
        console.error('Error al intentar desactiva un empleado', error);
      }
    },
    /**
     * Reactiva un empleado
     * @param {number} employeeId Id del empleado a reactivar
     */
    async enableEmployee(employeeId) {
      try {
        const employee = this.inactiveItems.find(e => e.id === employeeId)
        const response = await this.postObj('/personas/un-delete-empleado', {
          id_empleado: employeeId
        },
        'reactivar')
        if (response.mensaje) {
          this.inactiveItems = this.inactiveItems.filter(e => e.id !== employeeId)
          this.items.push(employee)
        }
      } catch (error) {
        console.error('Error al intentar reactivar un empleado', error);
      }
    }
  }
}
</script>
